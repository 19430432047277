<template>
    <section v-if="initiationId">
        <div id="reopen-confirm-job-vacancy-v2" uk-modal esc-close="false" bg-close="false">
            <div class="uk-modal-dialog">
                <button class="uk-modal-close-default" type="button" uk-close @click="closeModal" />
                <div class="uk-modal-header uk-flex-inline uk-width-1-1">
                    <img
                        src="/images/job_applicant/refresh-white.svg"
                        alt="Close Job Vacancy"
                        class="header-icon"
                    />
                    <h2 class="uk-modal-title header-font">(Confirm) Reopen this Vacancy</h2>
                </div>
                <section class="uk-modal-body space-y-7.5 uk-display-block" style="padding: 20px; color: black;">
                    <div class="uk-width-1-1">
                        <p>
                            Are you sure you want to re-open this vacancy on <span class="font-bold">{{ formattedToday }}</span> and with this changed information below :
                        </p>
                        <ul class="uk-margin-remove font-bold">
                            <li>{{ `'Due date'` }} <span class="font-normal">date set on</span> {{ formattedDueDate || "November 09, 2023" }}.</li>
                            <li>{{ `'Start Work'` }} <span class="font-normal">date set on</span> {{ formattedStartWork || "December 01, 2023" }}.</li>
                        </ul>
                    </div>

                    <div v-if="!isEmptyForm" class="uk-width-1-1 reminder-box">
                        <p class="uk-margin-remove">This new information below also will be updated.</p>
                        <div class="uk-width-1-1" style="margin-top: 16px;">
                            <ol class="uk-margin-remove uk-padding-remove space-y-3">
                                <li
                                    v-for="(key, index) in updatedKeys"
                                    class="uk-flex"
                                    style="padding-left: 18px;"
                                    :key="`${index}-${key}`"
                                >
                                    <span class="question-list uk-text-nowrap">{{index + 1}}. {{formatUpdatedForm[key].name}}</span>
                                    <p
                                        class="uk-width-1-1 uk-margin-remove line-clamp-2 font-semibold"
                                        :class="[!formatUpdatedForm[key].value.includes(' ') && 'break-all']"
                                    >
                                        : {{formatUpdatedForm[key].value}}
                                    </p>
                                </li>
                            </ol>
                        </div>
                    </div>

                    <div class="uk-width-1-1 uk-text-center">
                        <span class="caution-box">
                            <img alt="caution icon" class="caution-icon" src="/images/job_applicant/danger.svg" />

                            Proceed with Caution
                        </span>
                    </div>
                </section>
                <div class="uk-modal-footer justify-between">
                    <button
                        class="uk-button"
                        style="border: 1px solid #EF5350; color: #EF5350; background: #FFF;"
                        @click="closeModal"
                    >
                        Cancel
                    </button>

                    <button
                        class="uk-button text-white"
                        :class="[submitLoading && 'btn-disabled' || 'uk-button-primary']"
                        @click="proceedReopen"
                    >
                        <template v-if="submitLoading">
                            <span uk-spinner="ratio: 0.5"></span>
                            <span class="uk-margin-small-left">Loading</span>
                        </template>
                        <template v-else>
                            Yes, I’m Sure
                        </template>
                    </button>
                </div>
            </div>
        </div>
    </section>
</template>
<script>

import {mapActions} from 'vuex';
import { notificationDanger } from '@/utils/notification';
import formatter from '@/utils/formatter';

const GENDER_LABEL = {
    female: 'Wanita',
    male: 'Pria',
};

export default {
    name: "ModalReopenConfirmJobVacancyV2",
    props: {
        initiationId: {
            type: String,
            default: null,
        },
        dueDate: {
            type: String,
            default: null,
        },
        startWork: {
            type: String,
            default: null,
        },
        formData: {
            type: Object,
            default: () => ({}),
        },
        updateFn: {
            type: Function,
            default: undefined,
        },
    },
    data() {
        return {
            submitLoading: false,
            tmpFormData: null,
            updatedKeys: [],
            jobTypes: {},
            educationLevels: {},
        };
    },
    watch: {
        initiationId(value) {
            if (value && this.formData?.id) {
                this.tmpFormData = JSON.parse(localStorage.getItem('job_detail_before_edit') || '{}');
                this.detectUpdatedData();
            }
        },
    },
    computed: {
        isEmptyForm() {
            return this.updatedKeys.length === 0;
        },
        timezoneOffset() {
            return formatter.dateTimezoneOffset(this.today);
        },
        today() {
            const date = new Date();
            date.setHours(0, 0, 0, 0);
            date.setMinutes(0 - date.getTimezoneOffset());
            return date.toISOString();
        },
        formattedToday() {
            return formatter.dateComplete(this.today);
        },
        formattedStartWork() {
            const actualISO = this.startWork.replace(this.timezoneOffset, 'Z');
            return formatter.dateComplete(actualISO);
        },
        formattedDueDate() {
            const actualISO = this.dueDate.replace(this.timezoneOffset, 'Z');
            return formatter.dateComplete(actualISO);
        },
        formatUpdatedForm() {
            return {
                // give this values based on AddJob.vue multiJobPost.formData
                admin_job_vacancy_id: {
                    name: 'PIC Admin',
                    value: this.formData.admin_job_vacancy_id?.name || this.formData.admin_job_vacancy_id,
                },
                title: {
                    name: 'Nama Pekerjaan',
                    value: this.formData?.title || '-',
                },
                job_preference_id: {
                    name: 'Related Job Preference(s)',
                    value: this.formData.job_preference_id?.name,
                },
                role_id: {
                    name: 'Job role',
                    value: this.formData.role_id?.name,
                },
                job_description: {
                    name: 'Deskripsi Pekerjaan',
                    value: this.formData?.job_description || '-',
                },
                job_type_id: {
                    name: 'Status/Tipe Pekerjaan',
                    value: this.jobTypes[this.formData.job_type_id]?.name || '-',
                },
                job_contracts_string: {
                    name: 'Contract',
                    value: this.formData.job_contracts.map(item => item.title).join(', '),
                },
                benefits: {
                    name: 'Benefit tambahan',
                    value: this.formData.benefits,
                },
                working_hour_start: {
                    name: 'Jam Kerja',
                    value: `${formatter.hourMinuteOnly(this.formData.working_hour_start)} - ${formatter.hourMinuteOnly(this.formData.working_hour_end)}`,
                },
                working_hour_end: {
                    name: 'Jam Kerja',
                    value: `${formatter.hourMinuteOnly(this.formData.working_hour_start)} - ${formatter.hourMinuteOnly(this.formData.working_hour_end)}`,
                },
                gender: {
                    name: 'Gender',
                    value: GENDER_LABEL[this.formData.gender] || 'Pria / Wanita',
                },
                age_group: {
                    name: 'Batasan Usia',
                    value: this.formData.age_group,
                },
                education_level_id: {
                    name: 'Pendidikan Minimal',
                    value: this.educationLevels[this.formData.education_level_id]?.name || '-',
                },
                skill_needed: {
                    name: 'Keahlian yang Diperlukan',
                    value: this.formData.skill_needed,
                },
                is_experience_needed: {
                    name: 'Butuh Pengalaman Kerja',
                    value: this.formData.is_experience_needed ? 'Ya' : 'Tidak',
                },
                required_docs_string: {
                    name: 'Required Docs',
                    value: this.formData.required_docs.map(item => item.label).join(', '),
                },
                additional_docs_string: {
                    name: 'Additional Docs',
                    value: this.formData.additional_docs.map(item => item.label).join(', '),
                },
                required_tests: {
                    name: 'Required Tests',
                    value: this.formData.required_tests.map(item => `${item?.name} (${item.minimum_score})`).join(', '),
                },
                additional_questions: {
                    name: 'Additional Questions',
                    value: this.formData.additional_questions.map(item => item.question).join(', '),
                },
            };
        },
    },
    async mounted() {
        try {
            await Promise.all([
                this.setJobTypeData(),
                this.setEducationLevelData(),
            ]);
        } catch (e) {
            notificationDanger(e);
        }
    },
    methods: {
        ...mapActions({
            updateStatus: 'initiation/updateStatusV4',
            getJobTypes: 'option_data/getJobTypes',
            getEducationLevels: 'option_data/getEducationLevels',
        }),
        async proceedReopen() {
            try {
                this.submitLoading = true;

                await this.submitReopen();
                this.$emit("success");
            } catch (e) {
                notificationDanger(e);
            } finally {
                setTimeout(() => this.submitLoading = false, 750);
                this.closeModal(750);
            }
        },
        async submitReopen() {
            let hasError;

            try {
                let updateResponse;
                const paramsUpdateStatus = {
                    id: this.initiationId,
                    status: 0,
                    job_status: 1,
                    start_date: this.today.replace('Z', this.timezoneOffset),
                    due_date: this.dueDate,
                    start_work: this.startWork,
                    timezone_offset: this.timezoneOffset,
                };

                if (!this.isEmptyForm && this.formData?.id) {
                    // remove all displayed formData
                    delete this.formData.additional_docs_string;
                    delete this.formData.required_docs_string;
                    delete this.formData.job_contracts_string;

                    const successResponse = await this.updateFn();
                    if (!successResponse) {
                        hasError = true;
                        throw new Error(successResponse?.message);
                    }

                    hasError = false;
                }

                updateResponse = await this.updateStatus(paramsUpdateStatus);
                if (
                    (!this.isEmptyForm && hasError === undefined || hasError) ||
                    (!updateResponse || updateResponse?.status !== 'OK')
                ) {
                    hasError = true;
                    throw new Error(updateResponse?.message || 'Failed to update status');
                }

                localStorage.removeItem('job_detail_before_edit');
                setTimeout(() => this.$emit("success"), 250);
            } catch (e) {
                notificationDanger(e);
            } finally {
                this.closeModal();
            }
        },
        async setJobTypeData() {
            try {
                this.jobTypes = {};
                const data = await this.getJobTypes();
                data.forEach(item => {
                    this.jobTypes[String(item._id)] = item;
                });
            } catch (e) {
                notificationDanger(e);
            }
        },
        async setEducationLevelData() {
            try {
                this.educationLevels = {};
                const data = await this.getEducationLevels();
                data.forEach(item => {
                    this.educationLevels[String(item._id)] = item;
                });
            } catch (e) {
                notificationDanger(e);
            }
        },
        closeModal(timeout) {
            window.UIkit.modal("#reopen-confirm-job-vacancy-v2").$destroy(true);
            this.$emit("cancel", timeout);
        },
        detectUpdatedData() {
            const filterKeys = Object.keys(this.formatUpdatedForm);

            // special case on formData
            this.formData.additional_docs_string = this.formData.additional_docs?.map(item => item._id).join(', ');
            this.formData.required_docs_string = this.formData.required_docs?.map(item => item.value).join(', ');
            this.formData.job_contracts_string = this.formData.job_contracts?.map(item => (item._id + " - " + item.title)).join(', ');

            this.tmpFormData.additional_docs_string = this.tmpFormData.additional_docs?.map(item => item._id).join(', ');
            this.tmpFormData.required_docs_string = this.tmpFormData.required_docs?.join(', ');
            this.tmpFormData.job_contracts_string = this.tmpFormData.job_contracts?.map(item => (item._id + " - " + item.title)).join(', ');
            this.tmpFormData.education_level_id = String(this.tmpFormData.education_level?._id);
            this.tmpFormData.job_type_id = String(this.tmpFormData.job_type?._id);
            this.tmpFormData.required_tests = this.tmpFormData.required_tests?.map(
                item => ({
                    _id: item.test_category_id?._id || item._id,
                    name: item.test_category_id?.name,
                    minimum_score: item.minimum_score
                })
            );

            this.updatedKeys = [];
            filterKeys.forEach(key => {
                let isUpdated = false;
                if (this.formData[key] !== this.tmpFormData[key]) {
                    isUpdated = true;

                    const isObject = this.formData[key]?.constructor === Object;
                    const isArray = Array.isArray(this.formData[key]);

                    if (isObject) {
                        isUpdated = String(this.formData[key]?._id) !== this.tmpFormData[key] && JSON.stringify(this.formData[key]) !== JSON.stringify(this.tmpFormData[key]);
                    } else if (isArray) {
                        isUpdated = !this.compareArray(this.formData[key], this.tmpFormData[key]);
                    } else if ((!this.formData[key] || this.formData[key] === '') && (!this.tmpFormData[key] || this.tmpFormData[key] === '')) {
                        isUpdated = false;
                    }

                    if (isUpdated && filterKeys.includes(key)) {
                        this.updatedKeys.push(key);
                    }
                }
            });
        },
        compareArray(arr1, arr2) {
            return arr1.length === arr2.length && JSON.stringify(arr1) === JSON.stringify(arr2);
        },
    },
};
</script>
<style scoped>
.btn-disabled {
    background-color: #979797;
    pointer-events: none;
}

.text-white {
    color: #fff !important;
}

.font-normal {
    font-weight: 400;
}

.font-semibold {
    font-weight: 600;
}

.font-bold {
    font-weight: 700;
}

.justify-between {
    display: flex;
    justify-content: space-between;
}

.header-font {
    font-size: 24px;
    line-height: 1.5;
    margin: 0 16px;
    letter-spacing: -0.2px;
}

.header-icon {
    border-radius: 100%;
    background: #80C686;
    padding: 8px;
    max-width: 40px;
    height: 40px;
    aspect-ratio: 1/1;
}

.caution-box {
    background: #FEBE1033;
    display: inline-flex;
    align-items: center;
    width: fit-content;
    padding: 5px 13px;
    font-weight: 600;
}

.caution-icon {
    padding: 0.5px;
    max-width: 20px;
    height: 20px;
    margin-right: 7px;
    aspect-ratio: 1/1;
}

.reminder-box {
    background: #F8F7F7;
    padding: 20px;
    border-radius: 4px;
    font-size: 12px;
}

.question-list {
    max-width: 156px;
    width: 100%;
    margin-right: 7px;
}

.line-clamp-2 {
    display: -webkit-box;
    word-break: break-word;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.break-all {
    word-break: break-all !important;
}

section.uk-modal-body > * + * {
    margin-top: 1.875rem !important;
}

.space-y-3 > * + * {
    margin-top: 12px !important;
}

</style>
